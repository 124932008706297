import axios, { AxiosInstance } from 'axios'
import { ENV } from 'env'

const appAxios: AxiosInstance = axios.create({
  baseURL: ENV,
})

const fetcher = async (url: string) => (await appAxios.get(url)).data

export { fetcher }
