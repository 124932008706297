import { useRef, useEffect, useState } from 'react'
import { Feature, Map as OLMap, View } from 'ol'
import { fromLonLat } from 'ol/proj'
import { MapOptions } from 'ol/Map'
import { Point } from 'ol/geom'
import Icon from 'ol/style/Icon'
import OSM from 'ol/source/OSM'
import Style from 'ol/style/Style'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import 'ol/ol.css'
import { Coordinate } from 'types/Coordinate'
import { Doctor } from 'types/Doctor'

interface MapProps {
  coordinate: Coordinate
  doctors: Doctor[]
}

function Map({ coordinate, doctors }: MapProps) {
  const mapRef = useRef<any>()
  const [map, setMap] = useState<OLMap>()
  const { latitude, longitude } = coordinate

  // Renders initial MAP config.
  useEffect(() => {
    const options: MapOptions = {
      target: mapRef.current,
      layers: [new TileLayer({ source: new OSM() })],

      view: new View({
        zoom: 10,
        center: fromLonLat([longitude, latitude]),
      }),
    }

    const mapObject = new OLMap(options)
    mapObject.setTarget(mapRef.current)
    setMap(mapObject)

    return () => {
      mapObject.setTarget(undefined)
      setMap(undefined)
    }
  }, [longitude, latitude])

  // Create DOCTOR ICON after map renders
  // Note only renders ONE DOCTOR ICON, because API Doctor's data doesnt contains longitude and latitude for each doctor. So, in this case, only renders one doctor that belongs to the selected PROVINCE coordinate.
  useEffect(() => {
    if (typeof map !== 'undefined') {
      if (doctors.length > 0) {
        // Instantiate new icon and layer to add into map
        const doctorMarkerFeature = new Feature({
          geometry: new Point(fromLonLat([longitude, latitude])),
          name: 'doctors',
        })

        const doctorIconLayer = new VectorLayer({
          className: 'doctorLayer',
          source: new VectorSource({
            features: [doctorMarkerFeature],
          }),

          style: new Style({
            image: new Icon({
              anchor: [0.5, 50],
              anchorXUnits: 'fraction',
              anchorYUnits: 'pixels',
              src: 'mapIcons/doctorIcon.png',
              scale: 0.05,
            }),
          }),
        })

        // Add new doctor icon layer into map
        map.addLayer(doctorIconLayer)
      } else {
        // Must iterate over layers and delete the doctorLayer in this way!
        map.getAllLayers().forEach((layer) => {
          if (layer.getClassName() === 'doctorLayer') {
            map.removeLayer(layer)
          }
        })
      }
    }
  }, [map, doctors])

  return <div className="h-full w-full" ref={mapRef} />
}
export default Map
