import { Input } from '@material-tailwind/react'
import { fetcher } from 'api/api'
import { useClickOut } from 'hooks/useClickOut'
import { useIsFirstRender } from 'hooks/useIsFirstRender'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import { DoctorsResponse } from 'types/Doctor'
import { SpecialitiesResponse } from 'types/Speciality'
import DoctorsList from '../DoctorsList/DoctorsList'
import SpecialitiesList from '../SpecialitiesList/SpecialitiesList'

interface InputAutocompleteProps {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSelect: (value: string) => void
  name: string
  error: boolean
}

function InputAutocomplete({
  name,
  value,
  onChange,
  onSelect,
  error,
}: InputAutocompleteProps) {
  const { data: specialitiesResponse, error: specialitiesError } = useSWR<
    SpecialitiesResponse,
    unknown
  >('/specialities', fetcher)

  const { data: doctorsResponse, error: doctorsError } = useSWR<
    DoctorsResponse,
    unknown
  >('/doctors', fetcher)

  const isLoadingSpecialities = useMemo(
    () =>
      typeof specialitiesError === 'undefined' &&
      typeof specialitiesResponse === 'undefined',
    [specialitiesError, specialitiesResponse]
  )

  const isLoadingDoctors = useMemo(
    () =>
      typeof doctorsError === 'undefined' &&
      typeof doctorsResponse === 'undefined',
    [doctorsError, doctorsResponse]
  )

  const isLoading = isLoadingDoctors || isLoadingSpecialities

  const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false)

  const showDropdown = () => setIsOpenDropdown(true)
  const hideDropdown = () => setIsOpenDropdown(false)

  const isFirstRender = useIsFirstRender()
  const anchorRef = useClickOut(() => hideDropdown())

  const specialities = useMemo(
    () =>
      isLoadingSpecialities
        ? []
        : specialitiesResponse?.specialities?.filter(({ nombre }) =>
            nombre.toLowerCase().includes(value.toLowerCase())
          ),
    [value, isLoadingSpecialities]
  )

  const doctors = useMemo(
    () =>
      isLoadingDoctors
        ? []
        : doctorsResponse?.doctors.filter(
            ({
              nombre,
              apellido_1: firstLastName,
              apellido_2: secondLastName,
            }) => {
              const fullName =
                `${nombre} ${firstLastName} ${secondLastName}`.toLowerCase()

              return fullName.includes(value.toLowerCase())
            }
          ),
    [value, isLoadingDoctors]
  )

  useEffect(() => {
    !isFirstRender && value.trim().length === 0 && showDropdown()
  }, [value])

  return (
    <div ref={anchorRef}>
      <Input
        className="relative"
        size="lg"
        label="¿Qué necesitas?"
        autoComplete="off"
        name={name}
        value={value}
        onChange={onChange}
        onFocus={showDropdown}
        error={error}
        disabled={isLoading}
      />
      {isOpenDropdown &&
        specialities &&
        doctors &&
        (specialities.length > 0 || doctors.length > 0) && (
          <div className="w-full bg-white z-10 top-12 rounded-md shadow-sm absolute border-[1px] border-gray-200 max-h-48 overflow-y-auto scrollbar">
            <div>
              <SpecialitiesList
                hideDropdown={hideDropdown}
                onSelect={onSelect}
                specialities={specialities}
              />
              <DoctorsList
                hideDropdown={hideDropdown}
                onSelect={onSelect}
                doctors={doctors}
              />
            </div>
          </div>
        )}
    </div>
  )
}
export default InputAutocomplete
