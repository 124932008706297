import { Select, Option, Tooltip } from '@material-tailwind/react'
import classNames from 'classnames'
import { PROVINCES_OPTIONS } from 'constants/provinces'
import { ReactNode } from 'react'

interface ProvincesSelectorProps {
  onChange: (value: ReactNode | undefined) => void
  value: string
}

function ProvincesSelector({ value, onChange }: ProvincesSelectorProps) {
  return (
    <div className="w-full relative flex">
      <Select size="lg" label="¿En dónde?" onChange={onChange} value={value}>
        {PROVINCES_OPTIONS.map(({ text, value, className }) => (
          <Option key={value} value={value} className={className}>
            {text}
          </Option>
        ))}
      </Select>

      <Tooltip offset={18} placement="bottom" content="Cerca de mí">
        <i
          className={classNames(
            'fa-solid fa-location-dot',
            'absolute w-4 h-4 p-2 top-[6px] right-10 cursor-pointer text-gray-700 hover:text-blue-400 transition-all'
          )}
          onClick={() => onChange('close')}
        />
      </Tooltip>
    </div>
  )
}
export default ProvincesSelector
