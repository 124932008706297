import {
  Avatar,
  Button,
  Card,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from '@material-tailwind/react'
import classNames from 'classnames'
import { Doctor } from 'types/Doctor'

interface DoctorCardProps {
  data: Doctor
}

const DoctorCard = ({ data }: DoctorCardProps) => {
  const {
    nombre,
    apellido_1: apellido1,
    apellido_2: apellido2,
    doctor_speciality: doctorSpecialities,
    company: { province, nombre_empresa: nombreEmpresa },
  } = data

  const DOCTOR_FULLNAME = `${nombre} ${apellido1} ${apellido2}`.toUpperCase()
  const specialities = doctorSpecialities
    ?.map((doctorSpeciality) => doctorSpeciality?.speciality?.nombre)
    .join(' - ')

  return (
    <Card className="grid p-4 gap-6 md:grid-cols-[3fr_2fr]">
      {/* LEFT SECTION */}
      <div className="flex flex-col gap-2">
        <div className="flex gap-3">
          {/* AVATAR SECTION */}
          <div className="w-24">
            <Avatar
              className="w-full"
              size="xl"
              src="https://cdn-icons-png.flaticon.com/512/18/18601.png" // Here must be the DOCTOR URL IMAGE. Currently is not provided so this icon is a placeholder
              alt="person"
              variant="circular"
            />
          </div>

          {/* NAME AND SPECIALITY */}
          <header className="w-full">
            <div className="flex justify-between gap-4">
              <h4 className="font-semibold">{DOCTOR_FULLNAME}</h4>
              {/* PRICE */}
              <div className="text-green-500 font-semibold">$1.00</div>{' '}
              {/* Here must be the DOCTOR PRICE. Currently is not provided so this amount is a placeholder */}
            </div>

            <h5 className="text-md">{specialities}</h5>

            <span className="text-sm">
              <i
                className={classNames('fa-solid fa-star', 'text-yellow-600')}
              />{' '}
              <span className="font-bold">5.0 - Excelente</span>{' '}
              {/* Here must be the DOCTOR RATING. Currently is not provided so this rating is a placeholder */}
            </span>
          </header>
        </div>

        {/* LOCATION TABS */}
        <Tabs value={province.nombre_provincia}>
          <TabsHeader
            className="bg-transparent flex gap-4"
            indicatorProps={{
              className:
                'shadow-none border-b-[1px] border-blue-400 rounded-none',
            }}
          >
            <Tab
              className="flex items-end gap-2 w-max text-sm rounded-md p-2 hover:text-blue-400"
              value={province.nombre_provincia}
            >
              <span>
                <i
                  className={classNames(
                    'fa-solid fa-location-dot',
                    'text-xs mr-2'
                  )}
                />
                {province.nombre_provincia}
              </span>
            </Tab>
          </TabsHeader>
          <TabsBody>
            <TabPanel className="py-0 px-2" value={province.nombre_provincia}>
              <span className="text-sm">{nombreEmpresa}</span>
            </TabPanel>
          </TabsBody>
        </Tabs>
      </div>

      {/* RIGHT SECTION */}
      <div className="flex flex-col gap-2">
        {/* AVAILABILITY MESSAGE */}
        <div className="flex gap-2">
          <div>
            <i
              className={classNames(
                'fa-regular fa-calendar',
                'text-sm text-red-400'
              )}
            />
          </div>
          <div>
            <span className="text-xs text-red-400">
              La disponibilidad de este profesional no es pública. Contáctanos y
              conoce sus horarios.
            </span>
          </div>
        </div>

        <Button
          variant="outlined"
          className="flex justify-center items-center gap-2 text-xs px-2"
        >
          <i className={classNames('fa-brands fa-whatsapp', 'text-md')} />
          <span>Contáctanos por Whatsapp</span>
        </Button>

        <Button
          variant="outlined"
          className="flex justify-center items-center gap-2 text-xs px-2"
        >
          <i className="fa-solid fa-phone" />
          <span>Contáctanos por llamada</span>
        </Button>
      </div>
    </Card>
  )
}
export default DoctorCard
