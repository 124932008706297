import { fetcher } from 'api/api'
import { Container } from 'components/Container'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import useSWR from 'swr'
import { DoctorCard } from './components/DoctorCard'
import { Map } from './components/Map'
import { normalize } from 'helpers/strings/normalize'
import { Doctor, DoctorsResponse } from 'types/Doctor'
import { SearchBar } from 'pages/HomePage/components/SearchBar'
import { Card } from '@material-tailwind/react'

const SearchPage = () => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const value = queryParams.get('value') as string
  const location = queryParams.get('location') as string
  const latitude = queryParams.get('lat') as string
  const longitude = queryParams.get('lon') as string

  const coordinate = {
    latitude: Number(latitude),
    longitude: Number(longitude),
  }

  const { data: doctorsResponse } = useSWR<DoctorsResponse>(`/doctors`, fetcher)

  const filterDoctors = ({
    nombre,

    doctor_speciality: doctorSpecialities,
    apellido_1: firstLastName,
    apellido_2: secondLastName,
  }: Doctor) => {
    const fullName = normalize(`${nombre} ${firstLastName} ${secondLastName}`)
    const formattedDoctorSpecialities = doctorSpecialities?.map(
      (doctorSpeciality) => normalize(doctorSpeciality?.speciality?.nombre)
    )

    return (
      formattedDoctorSpecialities.includes(normalize(value)) ||
      fullName.includes(normalize(value))
    )
  }
  const filterDoctorsByLocation = ({ company }: Doctor) => {
    const doctorLocation = company.province.nombre_provincia

    return normalize(doctorLocation) === normalize(location)
  }

  const filteredDoctors = useMemo(
    () =>
      doctorsResponse?.doctors
        .filter(filterDoctors)
        .filter(filterDoctorsByLocation) ?? [],
    [doctorsResponse, value, location]
  )
  return (
    <Container extraClassNames="h-full flex flex-col gap-6 md:w-10/12 overflow-y-auto">
      <SearchBar querySearchCriteria={value} queryLocation={location} />

      <div className="grid gap-4 py-4 h-[95%] md:grid-cols-2 ">
        <section className="h-full flex flex-col gap-4 overflow-y-auto scrollbar">
          <header className="text-center md:text-start">
            <strong className="text-lg font-medium">
              {filteredDoctors?.length}{' '}
              {filteredDoctors?.length === 1 ? 'profesional' : 'profesionales'}{' '}
              <span className="text-blue-400 underline">
                ({value.toLowerCase()})
              </span>{' '}
              en esta zona
            </strong>
          </header>

          {/*  */}
          <div className="flex flex-col gap-4 pr-2">
            {filteredDoctors.length > 0 ? (
              filteredDoctors?.map((doctor) => (
                <DoctorCard key={doctor.doctor_id} data={doctor} />
              ))
            ) : (
              <Card className="p-8">
                <h5 className="text-xl text-red-400 font-bold text-center">
                  Vaya, al parecer no hay especialistas de este tipo en esta
                  zona. Intenta con otras posibilidades!
                </h5>
              </Card>
            )}
          </div>
        </section>

        <section className="h-full">
          <Map coordinate={coordinate} doctors={filteredDoctors} />
        </section>
      </div>
    </Container>
  )
}

export default SearchPage
