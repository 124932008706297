import { Doctor } from 'types/Doctor'

interface DoctorsListProps {
  doctors: Doctor[]
  onSelect: (value: string) => void
  hideDropdown: () => void
}

const DoctorsList = ({ doctors, onSelect, hideDropdown }: DoctorsListProps) => {
  return (
    <div>
      {doctors.length > 0 && (
        <h4 className="font-bold bg-gray-300 px-4 py-2 flex gap-4 items-center text-gray-700">
          Doctores
        </h4>
      )}
      <ul>
        {doctors
          ?.sort((a, b) =>
            a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase())
          )
          .map(
            ({
              nombre,
              doctor_id: doctorId,
              apellido_1: firstLastname,
              apellido_2: secondLastname,
            }) => {
              const fullName = `${nombre} ${firstLastname} ${secondLastname}`

              return (
                <li
                  className="px-4 py-2 flex gap-4 items-center text-gray-700 cursor-pointer transition-all hover:bg-gray-200 "
                  onClick={() => {
                    onSelect(fullName)
                    hideDropdown()
                  }}
                  key={`${doctorId}-${nombre}-${firstLastname}-${secondLastname}`}
                >
                  {fullName}
                </li>
              )
            }
          )}
      </ul>
    </div>
  )
}
export default DoctorsList
