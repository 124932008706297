import classNames from 'classnames'
import { Children } from 'types/common/Children'

interface ContainerProps {
  children: Children
  extraClassNames?: string
}

const Container = ({ extraClassNames, children }: ContainerProps) => {
  return (
    <div
      className={classNames('mx-auto w-full pt-12 md:w-2/3', extraClassNames)}
    >
      {children}
    </div>
  )
}

export default Container
