import { Button } from '@material-tailwind/react'
import { provincesCoordinatesMap } from 'constants/provinces/provincesCoordinates'
import { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Coordinate } from 'types/Coordinate'
import { Province } from 'types/Province'
import { InputAutocomplete } from '../InputAutocomplete'
import { ProvincesSelector } from '../ProvincesSelector'

interface SearchBarProps {
  querySearchCriteria?: string
  queryLocation?: string
}

const SearchBar = ({ querySearchCriteria, queryLocation }: SearchBarProps) => {
  const navigate = useNavigate()
  const [searchCriteria, setSearchCriteria] = useState<string>(
    querySearchCriteria ?? ''
  )

  const [location, setLocation] = useState<string>(queryLocation ?? 'san jose')
  const [coordinates, setCoordinate] = useState<Coordinate>(
    provincesCoordinatesMap[(queryLocation as Province) ?? 'san jose']
  )
  const [emptyError, setEmptyError] = useState<boolean>(false)

  const onChangeSearchCriteria = (event: ChangeEvent<HTMLInputElement>) => {
    if (emptyError) {
      setEmptyError(false)
    }

    setSearchCriteria(event.target.value)
  }

  const onSelectSearchCriteria = (value: string) => setSearchCriteria(value)

  const onChangeLocation = (value: ReactNode) => setLocation(value as string)

  const handleSubmit = () => {
    if (searchCriteria.trim().length === 0) {
      setEmptyError(true)
      return
    }

    const { latitude, longitude } = coordinates

    const navigationURL = `/search?&value=${searchCriteria.toUpperCase()}&location=${location}&lat=${latitude}&lon=${longitude}`

    navigate(navigationURL)
  }

  useEffect(() => {
    if (location === 'close') {
      const geolocation = navigator.geolocation
      geolocation.getCurrentPosition(
        (response) => {
          const {
            coords: { latitude, longitude },
          } = response
          setCoordinate({ latitude, longitude })
        },
        (err) => console.log(err)
      )
    } else {
      setCoordinate(provincesCoordinatesMap[location as Province])
    }
  }, [location])

  return (
    <div className="mx-auto w-10/12 bg-white p-8 rounded-xl shadow-lg">
      <form
        onSubmit={(event: any) => {
          event.preventDefault()
          handleSubmit()
        }}
        className="w-full flex flex-col items-end gap-6 md:flex-row"
      >
        <div className="w-full relative md:w-3/6">
          <InputAutocomplete
            value={searchCriteria}
            onChange={onChangeSearchCriteria}
            onSelect={onSelectSearchCriteria}
            error={emptyError}
            name="searchCriteria"
          />
        </div>

        <div className="w-full md:w-2/6">
          <ProvincesSelector onChange={onChangeLocation} value={location} />
        </div>

        <Button className="w-full md:w-1/6" onClick={handleSubmit}>
          Buscar
        </Button>
      </form>
    </div>
  )
}

export default SearchBar
