import { Speciality } from 'types/Speciality'

interface SpecialitiesListProps {
  specialities?: Speciality[]
  onSelect: (value: string) => void
  hideDropdown: () => void
}

const SpecialitiesList = ({
  specialities = [],
  onSelect,
  hideDropdown,
}: SpecialitiesListProps) => {
  const sortedSpecialities = specialities.sort((a, b) =>
    a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase())
  )

  return (
    <div>
      {specialities.length > 0 && (
        <h4 className="font-bold bg-gray-300 px-4 py-2 flex gap-4 items-center text-gray-700">
          Especialidades
        </h4>
      )}

      <ul>
        {sortedSpecialities.map(({ nombre, codigo }) => (
          <li
            className="px-4 py-2 flex gap-4 items-center text-gray-700 cursor-pointer transition-all hover:bg-gray-200 "
            onClick={() => {
              onSelect(nombre)
              hideDropdown()
            }}
            key={`${codigo}-${nombre}`}
          >
            {nombre}
          </li>
        ))}
      </ul>
    </div>
  )
}
export default SpecialitiesList
