import { Card, CardBody } from '@material-tailwind/react'
import { Container } from 'components/Container'
import { SearchBar } from './components/SearchBar'

// EXAMPLE CARDS

const HomePage = () => {
  return (
    <Container extraClassNames="flex flex-col gap-12 px-4">
      <header className="text-center flex flex-col gap-2">
        <h1 className="text-5xl font-bold text-blue-400">
          Buscador de especialidades
        </h1>
        <h2 className="text-lg">Encuentra profesionales de la salud</h2>
      </header>

      <section>
        <SearchBar />
      </section>

      <section className="w-full grid gap-6 md:grid-cols-3">
        <Card className=" overflow-hidden">
          <figure className="w-full h-full">
            <img src="homeCardImages/image1.png" className="object-contain" />
          </figure>

          <CardBody>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat,
            iusto nisi tenetur omnis soluta repellendus distinctio, at alias
            harum temporibus perferendis inventore! Voluptatum non facilis
            minima quae iste debitis. Alias.
          </CardBody>
        </Card>
        <Card className=" overflow-hidden">
          <figure className="w-full h-full">
            <img src="homeCardImages/image1.png" className="object-contain" />
          </figure>

          <CardBody>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat,
            iusto nisi tenetur omnis soluta repellendus distinctio, at alias
            harum temporibus perferendis inventore! Voluptatum non facilis
            minima quae iste debitis. Alias.
          </CardBody>
        </Card>
        <Card className=" overflow-hidden">
          <figure className="w-full h-full">
            <img src="homeCardImages/image1.png" className="object-contain" />
          </figure>

          <CardBody>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat,
            iusto nisi tenetur omnis soluta repellendus distinctio, at alias
            harum temporibus perferendis inventore! Voluptatum non facilis
            minima quae iste debitis. Alias.
          </CardBody>
        </Card>
      </section>
    </Container>
  )
}

export default HomePage
