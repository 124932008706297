import { ProvinceOption } from 'types/Province'

const PROVINCES_OPTIONS: ProvinceOption[] = [
  {
    text: 'San José',
    value: 'san jose',
  },
  {
    text: 'Alajuela',
    value: 'alajuela',
  },
  {
    text: 'Cartago',
    value: 'cartago',
  },
  {
    text: 'Heredia',
    value: 'heredia',
  },
  {
    text: 'Guanacaste',
    value: 'guanacaste',
  },
  {
    text: 'Puntarenas',
    value: 'puntarenas',
  },
  {
    text: 'Limón',
    value: 'limon',
  },
  {
    text: 'Cerca de mí',
    value: 'close',
    className: 'text-blue-400 bg-blue-50  transition-all',
  },
]

export default PROVINCES_OPTIONS
