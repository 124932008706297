import { ProvincesCoordinatesMap } from 'types/Province'

const provincesCoordinatesMap: ProvincesCoordinatesMap = {
  'san jose': {
    latitude: 10.0179008,
    longitude: -84.2062417,
  },

  alajuela: {
    latitude: 10.460632,
    longitude: -84.46725504158994,
  },

  cartago: {
    latitude: 9.81589045,
    longitude: -83.69430288751238,
  },

  guanacaste: {
    latitude: 10.378844050000001,
    longitude: -85.43382874573226,
  },

  heredia: {
    latitude: 10.376781300000001,
    longitude: -84.01397213865732,
  },

  limon: {
    latitude: 10.0971011,
    longitude: -83.33945429672625,
  },

  puntarenas: {
    latitude: 9.9785506,
    longitude: -84.8309757,
  },
}

export { provincesCoordinatesMap }
